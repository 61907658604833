
.Loading-icon {
  height: 10vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .Loading-icon {
    animation: Loading-icon-spin infinite 0.8s linear;
  }
}


@keyframes Loading-icon-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
